import React, {useEffect, useState} from "react";
import {Grid} from "@material-ui/core";
import TgfDropDownList from "../../../../../../../core/kendo/form/inputs/TgfDropDownList";
import TgfNumericTextBox from "../../../../../../../core/kendo/form/inputs/TgfNumericTextBox";
import "./ShipmentContent.scss";
import TgfToggle from "../../../../../../../core/kendo/form/inputs/TgfToggle";
import {Label} from "@progress/kendo-react-labels";
import TgfTextBox from "../../../../../../../core/kendo/form/inputs/TgfTextBox";
import useFieldValidator from "../../../../../../../crm/components/customHooks/form/validation/useFieldValidator2.0";
import FieldError from "../../../../../../../crm/components/inputs/FieldError";
import TgfButtonBasic from "../../../../../../../core/kendo/buttons/TgfButtonBasic";
import {SelectProductModal} from "../../../SelectProductModal/SelectProductModal";
import {getShipmenContentValidationSchema} from "../validation/shipmentContentValidationSchema";
import InfoIconTooltip from "../../../../../../../core/components/Tooltips/InfoIconTooltip/InfoIconTooltip";
import CategoryIcon from "@material-ui/icons/Category";

export const ShipmentContent = (props) => {
    const {
        type,
        idx,
        id,
        shipmentContentsAreValid,
        setShipmentContentsAreValid,
        formValues,
        formData,
        setFormValues,
        setIsDirty,
        customerId,
        activeStepIdx,
        disableAll = false,
        setStackableHasBeenToggled,
        setHazmatHasBeenToggled
    } = props;

    const [productModalOpen, setProductModalOpen] = useState(false);
    const shipmentContentValidationSchema = getShipmenContentValidationSchema(type);
    const fieldErrors = useFieldValidator(shipmentContentValidationSchema, formValues.shipmentContents[id]);

    useEffect(() => {
        if (!disableAll) {
            if (Object.keys(fieldErrors).length !== 0) {
                shipmentContentsAreValid[idx] = false;
            } else {
                shipmentContentsAreValid[idx] = true;
            }
            setShipmentContentsAreValid([...shipmentContentsAreValid]);
        }
    }, [fieldErrors]);

    const applyFormValue = (name, value) => {
        setFormValues({
            ...formValues,
            shipmentContents: {
                ...formValues.shipmentContents,
                [id]: {
                    ...formValues.shipmentContents[id],
                    [name]: value,
                },
            },
        });
        setIsDirty(true);
    };

    const calcDensity = ({
                             unitCount = 0,
                             weightLbs = 0,
                             dimensionsHeight = 0,
                             dimensionsWidth = 0,
                             dimensionsLength = 0,
                         }) => {
        const density = ((weightLbs / ((dimensionsLength * dimensionsWidth * dimensionsHeight) / 1728)) / unitCount).toFixed(2);
        return isFinite(parseFloat(density)) ? parseFloat(density) : null;
    };

    const handleChangeAndCalcDensity = (e) => {
        const cloneWithUpdatedValues = {
            ...formValues.shipmentContents[id],
            [e.target.name]: e.target.value,
        };

        cloneWithUpdatedValues.density = calcDensity(cloneWithUpdatedValues);

        setFormValues({
            ...formValues,
            shipmentContents: {
                ...formValues.shipmentContents,
                [id]: {...cloneWithUpdatedValues},
            },
        });
        setIsDirty(true);
    };

    const handleDropdownChange = (e) => {
        applyFormValue(e.target.name, e.target.value?.id);
    };

    const handleChange = (e) => {
        applyFormValue(e.target.name, e.target.value);
    };

    const handleStackableChange = (e) => {
        setStackableHasBeenToggled(true);
        applyFormValue(e.target.name, e.target.value)
    }

    const handleHazMatChange = (e) => {
        setHazmatHasBeenToggled(true);
        if(e.target.value === true) {
            setFormValues({
                ...formValues,
                shipmentContents: {
                    ...formValues.shipmentContents,
                    [id]: {
                        ...formValues.shipmentContents[id],
                        [e.target.name]: e.target.value,
                        hazmatType: 1,
                        hazmatCode: "Quote",
                        hazmatClass: 30,
                        packingGroupNumber: 2,
                    },
                },
            });
        }
        else {
            setFormValues({
                ...formValues,
                shipmentContents: {
                    ...formValues.shipmentContents,
                    [id]: {
                        ...formValues.shipmentContents[id],
                        [e.target.name]: e.target.value,
                        hazmatType: null,
                        hazmatCode: null,
                        hazmatClass: null,
                        packingGroupNumber: null,
                    },
                },
            });
        }
        setIsDirty(true);
    }

    const handleRemoveShipmentContentRow = () => {
        const clone = {...formValues.shipmentContents};
        delete clone[id];
        setFormValues({
            ...formValues,
            shipmentContents: clone,
        });

        shipmentContentsAreValid.splice(1, idx);
        setShipmentContentsAreValid([...shipmentContentsAreValid]);
    };

    const handleOpenProductModal = () => {
        setProductModalOpen(true);
    };

    const handleCloseProductModal = () => {
        setProductModalOpen(false);
    };

    const handleProductDescriptionChange = (e) => {
        const productClone = {...formValues.shipmentContents[id].product};
        productClone.productDescription = e.target.value;
        applyFormValue("product", {...productClone});
    };

    const tabIndexAddition = (10 * idx);

    return (
        <>
            <Grid item xs={12} md={12} lg={12}>
                {productModalOpen && !disableAll &&
                    <SelectProductModal
                        onClose={handleCloseProductModal}
                        customerId={customerId}
                        formValues={formValues}
                        setFormValues={setFormValues}
                        shipmentContentId={id}
                    />
                }
                <span className={"shipment-contents__row-id"}>{`#${idx + 1}`}</span>
                <Grid container spacing={2} alignItems={"center"} className={"shipment-contents-container"}>
                        <Grid item className={`grid-item remove-button`}>
                            {(id > 0 && activeStepIdx === 1 && !disableAll) &&
                                    <TgfButtonBasic
                                        onClick={handleRemoveShipmentContentRow}
                                        className={"shipment-contents__remove-row-btn"}
                                    >
                                        X
                                    </TgfButtonBasic>
                            }
                        </Grid>
                            <Grid item className="grid-item package-type">
                                <Label>Package Type</Label>
                                <InfoIconTooltip title={activeStepIdx === 1 ? `Package Types are required at the time of quoting and cannot be changed later during the booking process.` : "Package Types are required at the time of quoting and cannot be changed here. If you need to make a change, click the Edit/Go Back button above."}/>
                                <br/>
                                <TgfDropDownList
                                    // label={"Package Type"}
                                    id={"packageType"}
                                    name={"packageType"}
                                    value={formValues.shipmentContents[id].packageType}
                                    data={formData?.packageTypeOptions ?? []}
                                    textField={"display"}
                                    dataItemKey={"id"}
                                    onChange={handleDropdownChange}
                                    disabled={activeStepIdx !== 1 || disableAll}
                                    tabIndex={4 + tabIndexAddition}
                                />
                                <FieldError>{fieldErrors.packageType}</FieldError>
                            </Grid>
                            <Grid item className="grid-item small">
                                <Label># of Units</Label>
                                <InfoIconTooltip title={activeStepIdx === 1 ?
                                    <>
                                        <p>Unit totals are required at the time of quoting and cannot be changed later during the booking process. </p>
                                        <p>Correct unit totals are essential for accurate rates and avoiding shipment issues.</p>
                                    </>
                                    :
                                    <>
                                        <p>Unit totals are required at the time of quoting and cannot be changed here. If you need to make a change, click the Edit/Go Back button above.</p>
                                        <p>Correct unit totals are essential for accurate rates and avoiding shipment issues.</p>
                                    </>
                                }/>
                                <br/>
                                <TgfNumericTextBox
                                    id={"unitCount"}
                                    name={"unitCount"}
                                    value={formValues.shipmentContents[id].unitCount}
                                    onChange={handleChangeAndCalcDensity}
                                    disabled={activeStepIdx !== 1 || disableAll}
                                    tabIndex={5 + tabIndexAddition}
                                    format={"n0"}
                                />
                                <FieldError>{fieldErrors.unitCount}</FieldError>
                            </Grid>
                            <Grid item className="grid-item">
                                <Grid container justifyContent={"space-between"} style={{flexWrap: "nowrap"}}>
                                    <Grid item>
                                        <Label>Product Description</Label>
                                        <InfoIconTooltip title={ activeStepIdx === 1 ?
                                            <>
                                                <p>Product Descriptions are required at the time of quoting and cannot be changed later during the booking process. </p>
                                                <p>Correct product descriptions are essential to avoiding rate and shipment issues.</p>
                                                <p>Be sure to include all proper information dependent upon the commodity being shipped.</p>
                                            </>
                                            :
                                            <>
                                                <p>Product Descriptions are required at the time of quoting and cannot be changed here. If you need to make a change, click the Edit/Go Back button above. </p>
                                                <p>Correct product descriptions are essential to avoiding rate and shipment issues.</p>
                                                <p>Be sure to include all proper information dependent upon the commodity being shipped.</p>
                                            </>
                                        }/>
                                    </Grid>
                                    <Grid item>
                                        {type === "customerQuote" && activeStepIdx === 1 && !disableAll &&
                                            <CategoryIcon onClick={handleOpenProductModal}
                                                          className={'select-product-btn'}/>
                                        }
                                    </Grid>
                                </Grid>
                                <TgfTextBox
                                    id={"productDescription"}
                                    name={"productDescription"}
                                    value={formValues.shipmentContents[id].product?.productDescription}
                                    onChange={handleProductDescriptionChange}
                                    disabled={activeStepIdx !== 1 || disableAll}
                                    tabIndex={type === "customerQuote" ? 6 + tabIndexAddition : null}
                                    showTooltip={true}
                                    maxLength={250}
                                />
                                <FieldError>{fieldErrors.product?.productDescription}</FieldError>
                            </Grid>
                            <Grid item className="grid-item nmfc-code">
                                <Label>NMFC</Label>
                                <InfoIconTooltip title={ activeStepIdx === 1 ?
                                    <>
                                        <p>NMFCs are required at the time of quoting and cannot be changed later during the booking process.</p>
                                        <p>Correct NMFCs are essential to avoiding rate and shipment issues.</p>
                                    </>
                                    :
                                    <>
                                        <p>NMFCs are required at the time of quoting and cannot be changed here. If you need to make a change, click the Edit/Go Back button above.</p>
                                        <p>Correct NMFCs are essential to avoiding rate and shipment issues.</p>
                                    </>
                                }/>
                                <br/>
                                <TgfTextBox
                                    id={"nmfcCode"}
                                    name={"nmfcCode"}
                                    value={formValues.shipmentContents[id].nmfcCode}
                                    onChange={handleChange}
                                    disabled={activeStepIdx !== 1 || disableAll}
                                    tabIndex={type === "customerQuote" ? 7 + tabIndexAddition : null}
                                />
                                <FieldError shouldRender={disableAll !== true}>{fieldErrors.nmfcCode}</FieldError>
                            </Grid>
                            <Grid item className="grid-item small">
                                <Label>Class</Label>
                                <InfoIconTooltip title={ activeStepIdx === 1 ?
                                    <>
                                        <p>Classes are required at the time of quoting and cannot be changed later during the booking process.</p>
                                        <p>Correct freight classes are essential for accurate rates and avoiding shipment issues.</p>
                                    </>
                                    :
                                    <>
                                        <p>Classes are required at the time of quoting and cannot be changed here. If you need to make a change, click the Edit/Go Back button above.</p>
                                        <p>Correct freight classes are essential for accurate rates and avoiding shipment issues.</p>
                                    </>
                                }/>
                                <br/>
                                <TgfDropDownList
                                    id={"class"}
                                    name={"class"}
                                    value={formValues.shipmentContents[id].class}
                                    data={formData?.freightClassOptions ?? []}
                                    textField={"display"}
                                    dataItemKey={"id"}
                                    onChange={handleDropdownChange}
                                    disabled={activeStepIdx !== 1 || disableAll}
                                    tabIndex={8 + tabIndexAddition}
                                />
                                <FieldError>{fieldErrors.class}</FieldError>
                            </Grid>
                            {activeStepIdx > 2 &&
                                <Grid item className="grid-item small">
                                    <TgfToggle
                                        label={"Log"}
                                        labelPosition={"top"}
                                        id={"logProduct"}
                                        name={"logProduct"}
                                        checked={formValues.shipmentContents[id].logProduct}
                                        onChange={handleChange}
                                    />
                                </Grid>
                            }
                            <Grid item className="grid-item said-to-contain">
                                <Label>Said to Contain</Label>
                                <InfoIconTooltip title={activeStepIdx === 1 ?
                                    <>
                                        <p>Said to Contain totals are NOT required for quoting or booking. However, they cannot be added or changed later during the booking process.</p>
                                        <p>Having such information included may help improve the documentation of the shipment, especially in the event of a claim or dispute.</p>
                                    </>
                                    :
                                    <>
                                        <p>Said to Contain totals are NOT required for quoting or booking. However, they cannot be added or changed here. If you need to make a change, click the Edit/Go Back button above.</p>
                                        <p>Having such information included may help improve the documentation of the shipment, especially in the event of a claim or dispute.</p>
                                    </>
                                }/>
                                <br/>
                                <TgfNumericTextBox
                                    id={"piecesCount"}
                                    name={"piecesCount"}
                                    value={formValues.shipmentContents[id].piecesCount}
                                    onChange={handleChange}
                                    disabled={activeStepIdx !== 1 || disableAll}
                                    tabIndex={9 + tabIndexAddition}
                                    format={"n0"}
                                />
                                <FieldError>{fieldErrors.PiecesCount}</FieldError>
                            </Grid>
                            <Grid item className="grid-item small">
                                <Label>Weight (lbs)</Label>
                                <InfoIconTooltip title={activeStepIdx === 1 ?
                                    <>
                                        <p>Weights are required at the time of quoting and cannot be changed later during the booking process.</p>
                                        <p>Correct weight totals are essential for accurate rates and avoiding shipment issues.</p>
                                    </>
                                    :
                                    <>
                                        <p>Weights are required at the time of quoting and cannot be changed here.  If you need to make a change, click the Edit/Go Back button above.</p>
                                        <p>Correct weight totals are essential for accurate rates and avoiding shipment issues.</p>
                                    </>
                                }/>
                                <br/>
                                <TgfNumericTextBox
                                    id={"weightLbs"}
                                    name={"weightLbs"}
                                    value={formValues.shipmentContents[id].weightLbs}
                                    onChange={handleChangeAndCalcDensity}
                                    disabled={activeStepIdx !== 1 || disableAll}
                                    tabIndex={10 + tabIndexAddition}
                                    format={"n0"}
                                />
                                <FieldError>{fieldErrors.weightLbs}</FieldError>
                            </Grid>
                            <Grid item className="grid-item dimensions">
                                <Label>Dimensions (inches)</Label>
                                <InfoIconTooltip title={activeStepIdx === 1 ?
                                    <>
                                        <p>Dimensions are required at the time of quoting and cannot be changed later during the booking process.</p>
                                        <p>Correct dimensions are essential for accurate rates and avoiding shipment issues.</p>
                                    </>
                                    :
                                    <>
                                        <p>Dimensions are required at the time of quoting and cannot be changed here.  If you need to make a change, click the Edit/Go Back button above.</p>
                                        <p>Correct dimensions are essential for accurate rates and avoiding shipment issues.</p>
                                    </>
                                }/>
                                <br/>
                                <Grid container spacing={2}>
                                    <Grid item xs={4}>
                                        <TgfNumericTextBox
                                            id={"dimensionsLength"}
                                            name={"dimensionsLength"}
                                            value={formValues.shipmentContents[id].dimensionsLength}
                                            onChange={handleChangeAndCalcDensity}
                                            placeholder={"L"}
                                            disabled={activeStepIdx !== 1 || disableAll}
                                            tabIndex={11 + tabIndexAddition}
                                            format={"n0"}
                                        />
                                        <FieldError>{fieldErrors.dimensionsLength}</FieldError>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TgfNumericTextBox
                                            id={"dimensionsWidth"}
                                            name={"dimensionsWidth"}
                                            value={formValues.shipmentContents[id].dimensionsWidth}
                                            onChange={handleChangeAndCalcDensity}
                                            placeholder={"W"}
                                            disabled={activeStepIdx !== 1 || disableAll}
                                            tabIndex={12 + tabIndexAddition}
                                            format={"n0"}
                                        />
                                        <FieldError>{fieldErrors.dimensionsWidth}</FieldError>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TgfNumericTextBox
                                            id={"dimensionsHeight"}
                                            name={"dimensionsHeight"}
                                            value={formValues.shipmentContents[id].dimensionsHeight}
                                            onChange={handleChangeAndCalcDensity}
                                            placeholder={"H"}
                                            disabled={activeStepIdx !== 1 || disableAll}
                                            tabIndex={13 + tabIndexAddition}
                                            format={"n0"}
                                        />
                                        <FieldError>{fieldErrors.dimensionsHeight}</FieldError>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item className="grid-item small">
                                <Label>Density</Label>
                                <br/>
                                <TgfNumericTextBox
                                    id={"density"}
                                    name={"density"}
                                    value={formValues.shipmentContents[id].density}
                                    disabled={true}
                                />
                            </Grid>
                            <Grid item className="grid-item small center-text">
                                <Label>Stackable?</Label>
                                <InfoIconTooltip title={activeStepIdx === 1 ?
                                    <>
                                        <p>Classifying contents as stackable is NOT required. However, this classification cannot be changed later during the booking process.</p>
                                        <p>Remember that, in the eyes of most carriers, how the freight is loaded at the time of pickup is what matters in terms of how many feet of space the shipment takes up. Classifying the freight as stackable in order to reduce the total feet of space will not matter if the shipper does not load it accordingly.</p>
                                    </>
                                    :
                                    <>
                                        <p>Classifying contents as stackable is NOT required. However, this classification cannot be changed here.  If you need to make a change, click the Edit/Go Back button above.</p>
                                        <p>Remember that, in the eyes of most carriers, how the freight is loaded at the time of pickup is what matters in terms of how many feet of space the shipment takes up. Classifying the freight as stackable in order to reduce the total feet of space will not matter if the shipper does not load it accordingly.</p>
                                    </>
                                }/>
                                <br/>
                                <TgfToggle
                                    labelPosition={"top"}
                                    id={"isStackable"}
                                    name={"isStackable"}
                                    checked={formValues.shipmentContents[id].isStackable}
                                    onChange={handleStackableChange}
                                    disabled={activeStepIdx !== 1 || disableAll}
                                />
                            </Grid>
                            <Grid item className="grid-item small center-text">
                                <Label>HazMat?</Label>
                                <InfoIconTooltip title={type === "customerQuote" ?
                                    <>
                                        <p>At this time, you can only quote HazMat shipments through the rating engine. If you intend to book the shipment, you must do so directly on Globaltranz's website, as you will not be allowed to book it through the rating engine.</p>
                                    </>
                                    :
                                    <>
                                        <p>Properly classifying contents as Hazmat is essential for accurate rates and avoiding shipment issues.</p>
                                        <p>At this time, you can only quote HazMat shipments through the rating engine.
                                            If you intend to book the shipment, you must do so directly on Globaltranz's
                                            website, as you will not be allowed to book it through the rating
                                            engine.</p>
                                    </>
                                }/>
                                <br/>
                                <TgfToggle
                                    labelPosition={"top"}
                                    id={"isHazMat"}
                                    name={"isHazMat"}
                                    checked={formValues.shipmentContents[id].isHazMat}
                                    onChange={handleHazMatChange}
                                    disabled={activeStepIdx !== 1 || disableAll}
                                />
                            </Grid>
                            {/*{(formValues.shipmentContents[id].isHazMat) &&*/}
                            {/*    <Grid item xs={12}>*/}
                            {/*        <Grid container className={"hazmat-container"} justifyContent={"center"}>*/}
                            {/*            {activeStepIdx === 1 &&*/}
                            {/*            <Grid item xs={12} style={{textAlign: "center"}}>*/}
                            {/*                <span className={'warning-text'}>*/}
                            {/*                    The below information is required for the above contents and cannot be changed beyond this page. Please remember to include this information as needed within the Product Description.*/}
                            {/*                </span>*/}
                            {/*            </Grid>*/}
                            {/*            }*/}
                            {/*            <Grid item sm={12} md={12} lg={"auto"}>*/}
                            {/*                <Label>Hazmat Type</Label>*/}
                            {/*                /!* TODO check on tooltip *!/*/}
                            {/*                <InfoIconTooltip title={`TBD.`}/>*/}
                            {/*                <br/>*/}
                            {/*                <TgfDropDownList*/}
                            {/*                    id={"hazmatType"}*/}
                            {/*                    name={"hazmatType"}*/}
                            {/*                    value={formValues.shipmentContents[id].hazmatType}*/}
                            {/*                    data={formData?.hazmatTypes ?? []}*/}
                            {/*                    textField={"display"}*/}
                            {/*                    dataItemKey={"id"}*/}
                            {/*                    onChange={handleDropdownChange}*/}
                            {/*                    disabled={activeStepIdx !== 1 || disableAll}*/}
                            {/*                    tabIndex={14 + tabIndexAddition}*/}
                            {/*                />*/}
                            {/*                <FieldError>{fieldErrors.hazmatType}</FieldError>*/}
                            {/*            </Grid>*/}
                            {/*            <Grid item sm={12} md={12} lg={"auto"}>*/}
                            {/*                <Label>Hazmat Code</Label>*/}
                            {/*                /!* TODO check on tooltip *!/*/}
                            {/*                <InfoIconTooltip title={`TBD.`}/>*/}
                            {/*                <br/>*/}
                            {/*                <TgfTextBox*/}
                            {/*                    id={"hazmatCode"}*/}
                            {/*                    name={"hazmatCode"}*/}
                            {/*                    value={formValues.shipmentContents[id].hazmatCode}*/}
                            {/*                    onChange={handleChange}*/}
                            {/*                    disabled={activeStepIdx !== 1 || disableAll}*/}
                            {/*                    tabIndex={15 + tabIndexAddition}*/}
                            {/*                />*/}
                            {/*                <FieldError>{fieldErrors.hazmatCode}</FieldError>*/}
                            {/*            </Grid>*/}
                            {/*            <Grid item sm={12} md={12} lg={2}>*/}
                            {/*                <Label>Hazmat Class</Label>*/}
                            {/*                /!* TODO check on tooltip *!/*/}
                            {/*                <InfoIconTooltip title={`TBD.`}/>*/}
                            {/*                <br/>*/}
                            {/*                <TgfDropDownList*/}
                            {/*                    id={"hazmatClass"}*/}
                            {/*                    name={"hazmatClass"}*/}
                            {/*                    value={formValues.shipmentContents[id].hazmatClass}*/}
                            {/*                    data={formData?.hazmatClasses ?? []}*/}
                            {/*                    textField={"fullDisplay"}*/}
                            {/*                    dataItemKey={"id"}*/}
                            {/*                    onChange={handleDropdownChange}*/}
                            {/*                    disabled={activeStepIdx !== 1 || disableAll}*/}
                            {/*                    tabIndex={16 + tabIndexAddition}*/}
                            {/*                />*/}
                            {/*                <FieldError>{fieldErrors.hazmatClass}</FieldError>*/}
                            {/*            </Grid>*/}
                            {/*            <Grid item sm={12} md={12} lg={2}>*/}
                            {/*                <Label>Packing Group</Label>*/}
                            {/*                /!* TODO check on tooltip *!/*/}
                            {/*                <InfoIconTooltip title={`TBD.`}/>*/}
                            {/*                <br/>*/}
                            {/*                <TgfDropDownList*/}
                            {/*                    id={"packingGroupNumber"}*/}
                            {/*                    name={"packingGroupNumber"}*/}
                            {/*                    value={formValues.shipmentContents[id].packingGroupNumber}*/}
                            {/*                    data={formData?.hazmatPackingGroups ?? []}*/}
                            {/*                    textField={"display"}*/}
                            {/*                    dataItemKey={"id"}*/}
                            {/*                    onChange={handleDropdownChange}*/}
                            {/*                    disabled={activeStepIdx !== 1 || disableAll}*/}
                            {/*                    tabIndex={17 + tabIndexAddition}*/}
                            {/*                />*/}
                            {/*                <FieldError>{fieldErrors.packingGroupNumber}</FieldError>*/}
                            {/*            </Grid>*/}
                            {/*        </Grid>*/}
                            {/*    </Grid>*/}
                            {/*}*/}
                        </Grid>

                </Grid>
        </>
    );
};
